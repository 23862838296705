import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../../styles/header/headerMaint.css";
import "animate.css";

export default function HeaderMaint() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);

  const toggleNavSmallScreen = () => {
    setToggleMenu(!toggleMenu);
  };
  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
      if (window.innerWidth > 685) {
        setToggleMenu(false);
      }
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <>
      <header>
        <div className="cont d-flex justify-content-between ">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo_full_marketing" className="arrlogo" />
          </Link>

          <button
            onClick={toggleNavSmallScreen}
            className="btn text-light bg-black menuTo"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>

          {(toggleMenu || largeur > 685) && (
            <nav className="navbar navbar-brand  wider ">
              <Link
                to="/"
                className="accP nav-hov animate__animated animate__fadeInDown nol"
              >
                Accueil
              </Link>
              {/* <div class="dropdown ">
                <Link
                  to="#"
                  className="text-decoration-none nav-hov animate__animated animate__fadeInDown nol"
                >
                  Nos réalisations
                </Link>
                <div class="dropdown-content border border-light border-3 rounded ">
                  <Link
                    to="/desgn"
                    className="text-decoration-none anima-dordow d-flex  align-items-center "
                  >
                    <div className="stick"></div>
                    <span className="spa-stic">Architecture d'interieur</span>
                  </Link>
                  <Link
                    to="/Food"
                    className="text-decoration-none anima-dordow d-flex  align-items-center "
                  >
                    <div className="stick"></div>
                    <span className="spa-stic">Branding</span>
                  </Link>
                  <Link
                    to="/Marketing"
                    className="text-decoration-none anima-dordow d-flex  align-items-center "
                  >
                    <div className="stick"></div>
                    <span className="spa-stic">Marketing digital</span>
                  </Link>
                </div>
              </div> */}
              {/* <Link
                to="/Clients"
                className="text-decoration-none nav-hov animate__animated animate__fadeInDown nol"
              >
                Nos clients
              </Link> */}
              <Link
                to="/Contact"
                className="text-decoration-none nav-hov animate__animated animate__fadeInDown nol border-bottom border-2 border-light "
              >
                Nous contacter
              </Link>
            </nav>
          )}
          {/* Nouveau code pour la sidebar */}
          {toggleMenu && largeur <= 685 && (
            <div className="sidebar">
              <div className="closeIcon" onClick={toggleNavSmallScreen}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div className="sidebarContent">
                {
                  <nav className="navbar navbar-brand d-flex wid ">
                    <Link
                      to="/"
                      className="accP nav-hov animate__animated animate__fadeInDown"
                      onClick={toggleNavSmallScreen}
                    >
                      Accueil
                    </Link>
                    {/* <div class="dropdown barSpic">
                      <Link
                        to="#"
                        className="text-decoration-none nav-hov animate__animated animate__fadeInDown"
                      >
                        Nos réalisations
                      </Link>
                      <div class="dropdown-content border border-light border-3 rounded ">
                        <Link
                          to="/desgn"
                          className="text-decoration-none anima-dordow d-flex  align-items-center"
                          onClick={toggleNavSmallScreen}
                        >
                          <div className="stick"></div>
                          <span className="spa-stic">
                            Architecture d'interieur
                          </span>
                        </Link>
                        <Link
                          to="/Food"
                          className="text-decoration-none anima-dordow d-flex  align-items-center "
                          onClick={toggleNavSmallScreen}
                        >
                          <div className="stick"></div>
                          <span className="spa-stic">Branding</span>
                        </Link>
                        <Link
                          to="/Marketing"
                          className="text-decoration-none anima-dordow d-flex  align-items-center "
                          onClick={toggleNavSmallScreen}
                        >
                          <div className="stick"></div>
                          <span className="spa-stic">Marketing digital</span>
                        </Link>
                      </div>
                    </div>
                    <Link
                      to="/Clients"
                      className="text-decoration-none nav-hov animate__animated animate__fadeInDown"
                      onClick={toggleNavSmallScreen}
                    >
                      Nos clients
                    </Link> */}
                    <Link
                      to="/Contact"
                      className="text-decoration-none nav-hov animate__animated animate__fadeInDown border border-4 border-ligh "
                      onClick={toggleNavSmallScreen}
                    >
                      Nous contacter
                    </Link>
                  </nav>
                }
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
}
