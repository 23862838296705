import React from "react";
import "../../styles/Competence/Competance.css";

const Competance = ({ imageUrl, imageAlt, title, text }) => {
  return (
    <div className="image-with-text d-flex flex-column text-center">
      <img src={imageUrl} alt={imageAlt} className="taiVideIma" />
      <div className="spaCompAgen">
        <h4 className="h_titquat">{title}</h4>
        <p className="ptexComp" style={{ whiteSpace: "pre-line" }}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default Competance;
