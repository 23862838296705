import React from "react";
import "../../styles/Cookie/CookiePolicyPage.css";

export default function CookiePolicyPage() {
  return (
    <>
      <div className="cookie-policy-page">
        <h1>Politique de Cookies</h1>
        <p>
          Notre site web utilise des cookies pour améliorer l'expérience
          utilisateur, fournir des fonctionnalités de réseaux sociaux et
          analyser notre trafic. Cette page fournit des informations sur ce que
          sont les cookies, les cookies que nous utilisons, et comment les
          gérer.
        </p>

        <h2>Qu'est-ce qu'un Cookie ?</h2>
        <p>
          Un cookie est un petit fichier texte stocké sur votre ordinateur ou
          appareil mobile lorsque vous visitez un site web. Les cookies sont
          largement utilisés pour faire fonctionner les sites web ou fonctionner
          plus efficacement, ainsi que pour fournir des informations aux
          propriétaires du site.
        </p>

        <h2>Types de Cookies Utilisés</h2>
        <p>
          <strong>Cookies Nécessaires :</strong> Ces cookies sont essentiels
          pour vous permettre de naviguer sur le site et d'utiliser ses
          fonctionnalités. Sans ces cookies, les services que vous avez demandés
          ne peuvent pas être fournis.
        </p>
        <p>
          <strong>Cookies de Performance et d'Analyse :</strong> Ces cookies
          collectent des informations sur la façon dont les visiteurs utilisent
          un site Web, par exemple, les pages les plus visitées et si les
          utilisateurs reçoivent des messages d'erreur.
        </p>
        <p>
          <strong>Cookies de Fonctionnalité :</strong> Ces cookies permettent au
          site de se souvenir des choix que vous faites (comme votre nom
          d'utilisateur, la langue ou la région où vous vous trouvez) et
          fournissent des fonctionnalités améliorées et plus personnelles.
        </p>

        <h2>Gestion des Cookies</h2>
        <p>
          Vous avez le contrôle sur l'utilisation des cookies. Vous pouvez les
          effacer via les paramètres de votre navigateur, vous empêchant ainsi
          de stocker des cookies de sites web. Notez cependant que cela peut
          avoir un impact sur la fonctionnalité et l'expérience utilisateur sur
          notre site.
        </p>

        <h2>Modifications de Notre Politique de Cookies</h2>
        <p>
          Nous pouvons mettre à jour notre politique de cookies de temps à
          autre. Toutes les modifications seront publiées sur cette page.
        </p>
      </div>
    </>
  );
}
