import React from "react";
import "../../styles/conformaliter/MentionLegal.css";

export default function MentionLegal() {
  return (
    <>
      <div className="m-5">
        <h1 className="text-center mt-5">MENTIONS LEGALES</h1>
        <h5 className="text-center">En vigueur au 10/11/2023</h5>
        <section className="firtSectCon">
          <article className="artiCon">
            <p>
              Conformément aux dispositions des Articles 6-III et 19 de la Loi
              n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
              numérique, dite L.C.E.N., il est porté à la connaissance des
              utilisateurs et visiteurs, ci-après l"Utilisateur", du site
              https://fullmarketing.fr/ , ci-après le "Site", les présentes
              mentions légales. <br /> <br /> La connexion et la navigation sur
              le Site par l’Utilisateur implique acceptation intégrale et sans
              réserve des présentes mentions légales. <br /> <br />
              Ces dernières sont accessibles sur le Site à la rubrique «{" "}
              <div className="mentEga">Mentions légales</div> ».
            </p>
          </article>
          <h3>ARTICLE 1 - L'EDITEUR</h3>
          <article className="artiCon">
            <p>
              L’édition et la direction de la publication du Site est assurée
              par Louis Mickael, domiciliée 39 rue Rousselle 92800, dont le
              numéro de téléphone est 0648233300, et l'adresse e-mail
              mickaelouis03@gmail.com.
              <br /> <br />
              ci-après l'"Editeur".
            </p>
          </article>
          <h3>ARTICLE 2 - L'HEBERGEUR</h3>
          <article className="artiCon">
            <p>
              L'hébergeur du Site est la société o2switch, dont le siège social
              est situé au 222-224 BD GUSTAVE FLAUBERT 63000 CLERMONT FERRAND ,
              <div className="mentEga">
                avec le numéro de téléphone : 0444446040 + adresse mail de
                contact
              </div>
            </p>
          </article>
          <h3>ARTICLE 3 - ACCES AU SITE</h3>
          <article className="artiCon">
            <p>
              Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de
              force majeure, interruption programmée ou non et pouvant découlant
              d’une nécessité de maintenance.
              <br /> <br />
              En cas de modification, interruption ou suspension du Site,
              l'Editeur ne saurait être tenu responsable.
            </p>
          </article>
          <h3>ARTICLE 4 - COLLECTE DES DONNEES</h3>
          <article className="artiCon">
            Le Site assure à l'Utilisateur une collecte et un traitement
            d'informations personnelles dans le respect de la vie privée
            conformément à la loi n°78-17 du 6 janvier 1978 relative à
            l'informatique, aux fichiers et aux libertés.En vertu de la loi
            Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur
            dispose d'un droit d'accès, de rectification, de suppression et
            d'opposition de ses données personnelles. L'Utilisateur exerce ce
            droit : <br />
            par mail à l'adresse email mickaelouis03@gmail.com <br /> <br />
            Toute utilisation, reproduction, diffusion, commercialisation,
            modification de toute ou partie du Site, sans autorisation de
            l’Editeur est prohibée et pourra entraînée des actions et poursuites
            judiciaires telles que notamment prévues par le Code de la propriété
            intellectuelle et le Code civil.
          </article>
        </section>
      </div>
    </>
  );
}
