import React from "react";
import "../../styles/phea/pheaClient.css";
import bigClient from "../../assets/bigClient.png";
import Icon from "../header/icon";

//pour les 2 animation differents animate__animated animate__fadeInRight ou celles dans la composition actuel
export default function PheaClient() {
  return (
    <>
      <div className="d-flex">
        <Icon />
        <div className="d-flex flex-column teSpCT animate__animated animate__fadeInRight ">
          <div className="imageCT">
            <img
              src={bigClient}
              alt="img_ex"
              width={"100%"}
              className="grimg"
            />
            <div className="cadreBC-1  bg-light-subtle "></div>
          </div>
        </div>
      </div>
    </>
  );
}
