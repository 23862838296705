import React from "react";
import { Link } from "react-router-dom";
import "../../styles/conformaliter/PolitiqueDeconf.css";

export default function PolitiqueDeconf() {
  return (
    <>
      <h1 className="poliConf">
        Politique de Confidentialité pour Full Marketing
      </h1>

      <p>
        Chez Full Marketing, accessible depuis fullmarketing.fr, l'une de nos
        principales priorités est la confidentialité de nos visiteurs. Ce
        document de Politique de Confidentialité contient les types
        d'informations qui sont collectées et enregistrées par Full Marketing et
        comment nous les utilisons.
      </p>

      <p>
        Si vous avez des questions supplémentaires ou besoin de plus
        d'informations sur notre Politique de Confidentialité, n'hésitez pas à
        nous contacter.
      </p>

      <h2>Fichiers Journaux</h2>

      <p>
        Full Marketing suit une procédure standard d'utilisation des fichiers
        journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent
        des sites web. Toutes les entreprises d'hébergement font cela et cela
        fait partie des analyses des services d'hébergement. Les informations
        recueillies par les fichiers journaux incluent les adresses de protocole
        internet (IP), le type de navigateur, le Fournisseur d'Accès Internet
        (FAI), les horodatages de date et d'heure, les pages de
        référence/sortie, et éventuellement le nombre de clics. Ces informations
        ne sont pas liées à des informations personnellement identifiables.
        L'objectif de ces informations est d'analyser les tendances,
        d'administrer le site, de suivre le mouvement des utilisateurs sur le
        site web, et de recueillir des informations démographiques.
      </p>

      <h2>Cookies et Balises Web</h2>

      <p>
        Comme tout autre site web, Full Marketing utilise des "cookies". Ces
        cookies sont utilisés pour stocker des informations, y compris les
        préférences des visiteurs, et les pages du site web que le visiteur a
        accédées ou visitées. Les informations sont utilisées pour optimiser
        l'expérience des utilisateurs en personnalisant le contenu de notre page
        web en fonction du type de navigateur des visiteurs et/ou d'autres
        informations.
      </p>

      <p>
        Pour plus d'informations générales sur les cookies, veuillez lire
        <Link to="/CookiePolicyPage">l'article "Cookies"</Link>
      </p>

      <h2>Politiques de Confidentialité</h2>

      <p>
        Vous pouvez consulter cette liste pour trouver la Politique de
        Confidentialité de chacun des partenaires publicitaires de Full
        Marketing.
      </p>

      <p>
        Les serveurs publicitaires tiers ou les réseaux publicitaires utilisent
        des technologies telles que les cookies, JavaScript ou les Balises Web
        qui sont utilisées dans leurs publicités respectives et les liens qui
        apparaissent sur Full Marketing, qui sont envoyés directement au
        navigateur des utilisateurs. Ils reçoivent automatiquement votre adresse
        IP lorsque cela se produit. Ces technologies sont utilisées pour mesurer
        l'efficacité de leurs campagnes publicitaires et/ou pour personnaliser
        le contenu publicitaire que vous voyez sur les sites web que vous
        visitez.
      </p>

      <p>
        Notez que Full Marketing n'a pas accès ni le contrôle sur ces cookies
        utilisés par les annonceurs tiers.
      </p>

      <h2>Politiques de Confidentialité des Tiers</h2>

      <p>
        La Politique de Confidentialité de Full Marketing ne s'applique pas aux
        autres annonceurs ou sites web. Ainsi, nous vous conseillons de
        consulter les Politiques de Confidentialité respectives de ces serveurs
        publicitaires tiers pour plus d'informations détaillées. Cela peut
        inclure leurs pratiques et instructions sur la façon de se désabonner de
        certaines options.
      </p>

      <p>
        Vous pouvez choisir de désactiver les cookies via les options de votre
        navigateur individuel. Pour connaître des informations plus détaillées
        sur la gestion des cookies avec des navigateurs web spécifiques, cela
        peut être trouvé sur les sites web respectifs des navigateurs. Quels
        sont les Cookies ?
      </p>

      <h2>Informations sur les Enfants</h2>

      <p>
        Une autre partie de notre priorité est d'ajouter une protection pour les
        enfants lorsqu'ils utilisent internet. Nous encourageons les parents et
        tuteurs à observer, participer et/ou surveiller et guider leur activité
        en ligne.
      </p>

      <p>
        Full Marketing ne collecte pas sciemment des Informations Personnelles
        Identifiables d'enfants de moins de 13 ans. Si vous pensez que votre
        enfant a fourni ce type d'informations sur notre site web, nous vous
        encourageons fortement à nous contacter immédiatement et nous ferons de
        notre mieux pour retirer rapidement ces informations de nos dossiers.
      </p>

      <h2>Politique de Confidentialité en Ligne Uniquement</h2>

      <p>
        Cette Politique de Confidentialité s'applique uniquement à nos activités
        en ligne et est valable pour les visiteurs de notre site web concernant
        les informations qu'ils ont partagées et/ou collectées chez Full
        Marketing. Cette politique n'est pas applicable aux informations
        collectées hors ligne ou via des canaux autres que ce site web.
      </p>

      <h2>Consentement</h2>

      <p>
        En utilisant notre site web, vous consentez par la présente à notre
        Politique de Confidentialité et acceptez ses Conditions Générales.
      </p>
    </>
  );
}
