import "././styles/App.css";
import { Routes, Route } from "react-router-dom";
/* import Home from "./pages/Home"; */
/* import Header from "./components/header/Header.jsx"; */
import HeaderMaint from "./components/header/HeaderMaint.jsx";
/* import Desgn from "./pages/desgn/desgn"; */
import Error from "./components/error/Erreur";
/* import Food from "./pages/Food/food"; */
import Marketing from "./pages/Marketing/Marketing";
import Clients from "./pages/clients/Clients";
import Contact from "./pages/contact/Contact";
import Footer from "./components/footer/footer";
import CookieCpt from "./components/Cookie/CookieCpt";
import MentionLegal from "./pages/conformaliter/MentionLegal.jsx";
import CookiePolicyPage from "./pages/conformaliter/CookiePolicyPage.jsx";
import PolitiqueDeconf from "./pages/conformaliter/PolitiqueDeconf.jsx";
import Condgeneutil from "./pages/conformaliter/Condgeneutil.jsx";

function App() {
  return (
    <div>
      <HeaderMaint />
      <Routes>
        <Route path="/" element={<Marketing />} />
        {/* 
        <Route path="/desgn" element={<Desgn />} />
        <Route path="/Food" element={<Food />} /> */}
        {/* <Route path="/Marketing" element={<Marketing />} /> */}
        <Route path="/Clients" element={<Clients />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/MentionLegal" element={<MentionLegal />} />
        <Route path="/CookiePolicyPage" element={<CookiePolicyPage />} />
        <Route path="/PolitiqueDeconf" element={<PolitiqueDeconf />} />
        <Route path="/Condgeneutil" element={<Condgeneutil />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
      <CookieCpt />
    </div>
  );
}

export default App;
