import React from "react";
import "../../styles/prez/prez.css";
import Presentati from "./Presentati";
import camera from "../../assets/pix_marketing/prezimg/camera.png";
import ecran from "../../assets/pix_marketing/prezimg/ecran.png";
import cmmanager from "../../assets/pix_marketing/prezimg/cmmanager.png";
import marketidinf from "../../assets/pix_marketing/prezimg/marketidinf.png";
import { Link } from "react-router-dom";

export default function Prez() {
  const donne = [
    {
      imageUrl: cmmanager,
      imageAlt: "img_decrit",
      title: "Community management",
    },
    {
      imageUrl: camera,
      imageAlt: "img_decrit",
      title: "Shooting\nphoto vidéo",
    },
  ];
  const donneDeux = [
    {
      imageUrl: ecran,
      imageAlt: "img_decrit",
      title: "Site web",
    },
    {
      imageUrl: marketidinf,
      imageAlt: "img_decrit",
      title: "Marketing d'influence",
    },
  ];
  return (
    <>
      <div>
        <div className="prezBox">
          <div className="textPrez text-center">
            <h2>Nos prestations principales</h2>
            <p>
              Nous proposons plusieurs prestations pour <br /> un service
              totalement clé en main.
            </p>
          </div>
          <div className="cubPrezAgence ">
            {donne.map((item, index) => (
              <Presentati
                key={index}
                imageUrl={item.imageUrl}
                imageAlt={item.imageAlt}
                title={item.title}
                className="colmPrezCub"
              />
            ))}
            {donneDeux.map((item, index) => (
              <Presentati
                key={index}
                imageUrl={item.imageUrl}
                imageAlt={item.imageAlt}
                title={item.title}
                className="colmPrezCub"
              />
            ))}
          </div>
          <div className="PrezButtAgenc">
            <Link to="/Contact">
              <button className="btn border-light  text-center buttPrezN">
                Travaillez avec nous !
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
