import React from "react";
import "../../styles/conformaliter/Condgeneutil.css";

export default function Condgeneutil() {
  return (
    <>
      <h2 className="cguti">
        <strong>Conditions Générales d'Utilisation</strong>
      </h2>

      <p>Bienvenue sur Full Marketing !</p>

      <p>
        Ces conditions générales définissent les règles et réglementations pour
        l'utilisation du site Web de Full Marketing, situé à fullmarketing.fr.
      </p>

      <p>
        En accédant à ce site web, nous supposons que vous acceptez ces
        conditions générales. Ne continuez pas à utiliser Full Marketing si vous
        n'acceptez pas de respecter toutes les conditions générales énoncées sur
        cette page.
      </p>

      <p>
        La terminologie suivante s'applique à ces Conditions Générales,
        Déclaration de Confidentialité et Avis de Non-Responsabilité et à tous
        les Accords : "Client", "Vous" et "Votre" se réfèrent à vous, la
        personne se connectant à ce site web et conforme aux termes et
        conditions de la Compagnie. "La Compagnie", "Nous-mêmes", "Nous",
        "Notre" et "Nos", se réfèrent à notre Compagnie. "Partie", "Parties", ou
        "Nous", se réfère à la fois au Client et à nous-mêmes. Tous les termes
        se réfèrent à l'offre, l'acceptation et la considération du paiement
        nécessaires pour entreprendre le processus de notre assistance au Client
        de la manière la plus appropriée dans le but explicite de répondre aux
        besoins du Client en ce qui concerne la fourniture des services déclarés
        de la Compagnie, conformément à et sous réserve de, la loi en vigueur
        des Pays-Bas. Tout usage de la terminologie ci-dessus ou d'autres mots
        au singulier, pluriel, majuscules et/ou il/elle ou ils, sont considérés
        comme interchangeables et donc comme se référant à la même chose.
      </p>

      <h3>
        <strong>Cookies</strong>
      </h3>

      <p>
        Nous utilisons des cookies. En accédant à Full Marketing, vous avez
        accepté d'utiliser des cookies en accord avec la Politique de
        Confidentialité de Full Marketing.
      </p>

      <p>
        La plupart des sites web interactifs utilisent des cookies pour nous
        permettre de récupérer les détails de l'utilisateur pour chaque visite.
        Les cookies sont utilisés par notre site web pour activer la
        fonctionnalité de certaines zones afin de faciliter la visite des
        personnes sur notre site web. Certains de nos partenaires
        affiliés/publicitaires peuvent également utiliser des cookies.
      </p>

      <h3>
        <strong>Licence</strong>
      </h3>

      <p>
        Sauf indication contraire, Full Marketing et/ou ses concédants de
        licence possèdent les droits de propriété intellectuelle pour tout le
        matériel sur Full Marketing. Tous les droits de propriété intellectuelle
        sont réservés. Vous pouvez accéder à cela depuis Full Marketing pour
        votre usage personnel soumis aux restrictions définies dans ces
        conditions générales.
      </p>

      <p>Vous ne devez pas :</p>
      <ul>
        <li>Republier du matériel de Full Marketing</li>
        <li>Vendre, louer ou sous-licencier du matériel de Full Marketing</li>
        <li>Reproduire, dupliquer ou copier du matériel de Full Marketing</li>
        <li>Redistribuer le contenu de Full Marketing</li>
      </ul>

      <p>Cet Accord commence à la date ci-dessous.</p>

      <p>
        Certaines parties de ce site offrent une opportunité aux utilisateurs de
        publier et échanger des opinions et informations dans certaines zones du
        site. Full Marketing ne filtre pas, n'édite pas, ne publie pas ni ne
        révise les Commentaires avant leur présence sur le site. Les
        commentaires ne reflètent pas les vues et opinions de Full Marketing, de
        ses agents et/ou affiliés. Les commentaires reflètent les vues et
        opinions de la personne qui poste leurs vues et opinions. Dans la mesure
        permise par les lois applicables, Full Marketing ne sera pas responsable
        pour les Commentaires ou pour toute responsabilité, dommages ou dépenses
        causés et/ou soufferts à la suite de toute utilisation et/ou publication
        et/ou apparition des Commentaires sur ce site web.
      </p>

      <p>
        Full Marketing se réserve le droit de surveiller tous les Commentaires
        et de retirer tout commentaire qui peut être considéré comme
        inapproprié, offensant ou provoquant une violation de ces Conditions
        Générales.
      </p>

      <p>Vous garantissez et déclarez que :</p>

      <ul>
        <li>
          Vous êtes autorisé à poster les Commentaires sur notre site web et
          avez toutes les licences et consentements nécessaires pour le faire;
        </li>
        <li>
          Les Commentaires n'envahissent aucun droit de propriété
          intellectuelle, y compris mais sans limitation le droit d'auteur, le
          brevet ou la marque déposée de tout tiers;
        </li>
        <li>
          Les Commentaires ne contiennent aucun matériel diffamatoire,
          calomnieux, offensant, indécent ou autrement illégal qui est une
          invasion de la vie privée
        </li>
        <li>
          Les Commentaires ne seront pas utilisés pour solliciter ou promouvoir
          des affaires ou des coutumes ou présenter des activités commerciales
          ou une activité illégale.
        </li>
      </ul>

      <p>
        Vous accordez par la présente à Full Marketing une licence non-exclusive
        pour utiliser, reproduire, éditer et autoriser d'autres à utiliser,
        reproduire et éditer vos Commentaires sous toutes les formes, formats ou
        médias.
      </p>

      <h3>
        <strong>Hyperliens vers notre Contenu</strong>
      </h3>

      <p>
        Les organisations suivantes peuvent créer un lien vers notre Site Web
        sans approbation écrite préalable :
      </p>

      <ul>
        <li>Agences gouvernementales;</li>
        <li>Moteurs de recherche;</li>
        <li>Organisations de presse;</li>
        <li>
          Les distributeurs d'annuaires en ligne peuvent créer un lien vers
          notre Site Web de la même manière qu'ils hyperlient vers les Sites Web
          d'autres entreprises répertoriées; et
        </li>
        <li>
          Entreprises accréditées à l'échelle du système à l'exception des
          organisations à but non lucratif sollicitant, des centres commerciaux
          caritatifs et des groupes de collecte de fonds caritatifs qui ne
          peuvent pas créer de lien vers notre site web.
        </li>
      </ul>

      <p>
        Ces organisations peuvent créer un lien vers notre page d'accueil, vers
        des publications ou vers d'autres informations du site web à condition
        que le lien : (a) ne soit en aucun cas trompeur; (b) n'implique pas
        faussement une sponsorship, un endossement ou une approbation de la
        partie liante et de ses produits et/ou services; et (c) s'intègre dans
        le contexte du site de la partie liante.
      </p>

      <p>
        Nous pouvons considérer et approuver d'autres demandes de lien des types
        d'organisations suivants :
      </p>

      <ul>
        <li>
          Sources d'informations consommateurs et/ou commerciales bien connues;
        </li>
        <li>Sites communautaires dot.com;</li>
        <li>
          Associations ou autres groupes représentant des œuvres caritatives;
        </li>
        <li>Distributeurs d'annuaires en ligne;</li>
        <li>Portails internet;</li>
        <li>Cabinets de comptabilité, de droit et de conseil; et</li>
        <li>Institutions éducatives et associations professionnelles.</li>
      </ul>

      <p>
        Nous approuverons les demandes de lien de ces organisations si nous
        décidons que : (a) le lien ne nous ferait pas paraître défavorablement à
        nous-mêmes ou à nos entreprises accréditées; (b) l'organisation n'a
        aucun dossier négatif avec nous; (c) le bénéfice pour nous de la
        visibilité de l'hyperlien compense l'absence de Full Marketing; et (d)
        le lien se trouve dans le contexte d'informations de ressources
        générales.
      </p>

      <p>
        Ces organisations peuvent créer un lien vers notre page d'accueil à
        condition que le lien : (a) ne soit en aucun cas trompeur; (b)
        n'implique pas faussement une sponsorship, un endossement ou une
        approbation de la partie liante et de ses produits ou services; et (c)
        s'intègre dans le contexte du site de la partie liante.
      </p>

      <p>
        Si vous faites partie des organisations listées au paragraphe 2
        ci-dessus et êtes intéressé à créer un lien vers notre site web, vous
        devez nous en informer en envoyant un e-mail à Full Marketing. Veuillez
        inclure votre nom, le nom de votre organisation, les informations de
        contact ainsi que l'URL de votre site, une liste de toutes les URL à
        partir desquelles vous avez l'intention de créer un lien vers notre Site
        Web, et une liste des URL sur notre site vers lesquelles vous souhaitez
        créer un lien. Attendez 2-3 semaines pour une réponse.
      </p>

      <p>
        Les organisations approuvées peuvent créer un hyperlien vers notre Site
        Web comme suit :
      </p>

      <ul>
        <li>Par l'utilisation de notre nom corporatif; ou</li>
        <li>Par l'utilisation de l'URL uniforme liée à; ou</li>
        <li>
          Par l'utilisation de toute autre description de notre Site Web liée à
          cela qui a du sens dans le contexte et le format du contenu sur le
          site de la partie liante.
        </li>
      </ul>

      <p>
        Aucune utilisation du logo de Full Marketing ou d'autres œuvres d'art ne
        sera autorisée pour créer un lien en l'absence d'un accord de licence de
        marque.
      </p>

      <h3>
        <strong>iFrames</strong>
      </h3>

      <p>
        Sans approbation préalable et permission écrite, vous ne pouvez pas
        créer de cadres autour de nos Pages Web qui modifient de quelque manière
        que ce soit la présentation visuelle ou l'apparence de notre Site Web.
      </p>

      <h3>
        <strong>Responsabilité du Contenu</strong>
      </h3>

      <p>
        Nous ne serons pas tenus responsables de tout contenu qui apparaît sur
        votre Site Web. Vous acceptez de nous protéger et de nous défendre
        contre toutes les réclamations qui se lèvent sur votre Site Web. Aucun
        lien ne doit apparaître sur un Site Web qui peut être interprété comme
        diffamatoire, obscène ou criminel, ou qui enfreint, viole ou prône
        l'enfreinte ou autre violation des droits de tiers.
      </p>

      <h3>
        <strong>Réserve de Droits</strong>
      </h3>

      <p>
        Nous nous réservons le droit de vous demander de supprimer tous les
        liens ou tout lien particulier vers notre Site Web. Vous approuvez de
        supprimer immédiatement tous les liens vers notre Site Web sur demande.
        Nous nous réservons également le droit de modifier ces termes et
        conditions et sa politique de lien à tout moment. En créant
        continuellement un lien vers notre Site Web, vous acceptez d'être lié et
        de suivre ces termes et conditions de lien.
      </p>

      <h3>
        <strong>Suppression de liens de notre site web</strong>
      </h3>

      <p>
        Si vous trouvez un lien sur notre Site Web qui est offensant pour une
        raison quelconque, vous êtes libre de nous contacter et de nous informer
        à tout moment. Nous envisagerons des demandes de suppression de liens,
        mais nous ne sommes pas obligés de le faire ou de vous répondre
        directement.
      </p>

      <p>
        Nous ne garantissons pas que les informations sur ce site web sont
        correctes, nous ne garantissons pas leur complétude ou leur exactitude;
        ni ne promettons de garantir que le site web reste disponible ou que le
        matériel sur le site web est tenu à jour.
      </p>

      <h3>
        <strong>Avertissement</strong>
      </h3>

      <p>
        Dans la mesure maximale permise par la loi applicable, nous excluons
        toutes les représentations, garanties et conditions relatives à notre
        site web et à l'utilisation de ce site web. Rien dans cet avertissement
        ne va :
      </p>

      <ul>
        <li>
          limiter ou exclure notre ou votre responsabilité pour décès ou
          blessure personnelle;
        </li>
        <li>
          limiter ou exclure notre ou votre responsabilité pour fraude ou fausse
          déclaration frauduleuse;
        </li>
        <li>
          limiter nos ou vos responsabilités de quelque manière que ce soit non
          autorisée par la loi applicable; ou
        </li>
        <li>
          exclure nos ou vos responsabilités qui ne peuvent être exclues en
          vertu de la loi applicable.
        </li>
      </ul>

      <p>
        Les limitations et interdictions de responsabilité énoncées dans cette
        section et ailleurs dans cet avertissement : (a) sont soumises au
        paragraphe précédent; et (b) régissent toutes les responsabilités
        découlant de l'avertissement, y compris les responsabilités
        contractuelles, en délit et pour violation du devoir statutaire.
      </p>

      <p>
        Tant que le site web et les informations et services sur le site web
        sont fournis gratuitement, nous ne serons pas responsables de toute
        perte ou dommage de quelque nature que ce soit.
      </p>
    </>
  );
}
