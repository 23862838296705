import React from "react";
import insta from "../../assets/logo_reseaux/insta.png";
import facebook from "../../assets/logo_reseaux/facebook.png";
import linkedind from "../../assets/logo_reseaux/linkedind.png";
import { Link } from "react-router-dom";
import "animate.css";
import "../../styles/header/header.css";

export default function Icon() {
  return (
    <>
      <div className=" desnone nanimate__animated animate__fadeInDown ">
        <div className="icon">
          <div className="in mis-V">
            <Link
              to="https://www.instagram.com/fullmarketing.fr/"
              className="navbar-brand"
            >
              <img src={insta} alt="instagram_de_full_marketing" />
            </Link>
          </div>
          <div className="fa mis-V">
            <Link
              to="https://m.facebook.com/fullmarketing.fr"
              className="navbar-brand"
            >
              <img src={facebook} alt="facebook_de_full_marketing" />
            </Link>
          </div>
          <div className="ke mis-V">
            <Link
              to="https://www.linkedin.com/company/full-marketing-puteaux"
              className="navbar-brand"
            >
              <img src={linkedind} alt="linkedind_de_full_marketing" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
