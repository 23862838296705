import React from "react";
import entreP from "../../assets/logo_marq/entreP.png";
import atelierDurum from "../../assets/logo_marq/atelierDurum.png";
import izzana from "../../assets/logo_marq/izzana.png";
import nomad from "../../assets/logo_marq/nomad.png";
import "../../styles/Alignlogo/Alignlogo.css";

export default function AlignlogoOne() {
  return (
    <>
      <p className="h_tr">ILS NOUS ONT FAIT CONFIANCE</p>
      <div className="space_align">
        <article className="d-flex flex-row align-items-baseline">
          <div className="log_mark">
            <img src={entreP} alt="lafabuica_logo" className="log1M entr" />
          </div>
          <div className="log_mark">
            <img src={atelierDurum} alt="atelierDurum_logo" className="log1M" />
          </div>
          <div className="log_mark">
            <img src={izzana} alt="izzana_logo" className="log1M" />
          </div>
          <div className="log_mark">
            <img src={nomad} alt="nomad_logo" className="log1M" />
          </div>
        </article>
      </div>
    </>
  );
}
