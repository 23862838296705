import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import "../../styles/Cookie/cookie.css";
import ReactGA from "react-ga";

export default function CookieCpt() {
  const [userConsentedToTracking, setUserConsentedToTracking] = useState(false);
  console.log(userConsentedToTracking);
  const initializeAnalytics = () => {
    ReactGA.initialize("G-KEBBGF6GLR");
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  const handleAccept = (acceptedByScrolling) => {
    initializeAnalytics();
    setUserConsentedToTracking(true);
    if (acceptedByScrolling) {
      console.log(
        "L'acceptation a été déclenchée par le défilement de l'utilisateur."
      );
    } else {
      console.log(
        "L'acceptation a été déclenchée en cliquant sur le bouton Accepter."
      );
    }
  };

  const handleDecline = () => {
    setUserConsentedToTracking(false);
    console.log("L'utilisateur a refusé les cookies.");
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        declineButtonText="Refuser"
        cookieName="userConsentCookie"
        style={{ background: "#2B373w", textAlign: "center" }}
        buttonStyle={{
          color: "#000000",
          fontSize: "13px",
          background: "#ffffff",
        }}
        declineButtonStyle={{ fontSize: "13px", color: "#4e503b" }}
        expires={150}
        enableDeclineButton={true}
        onAccept={handleAccept}
        onDecline={handleDecline}
      >
        <div className="cookband">
          <p className="pCook">
            Ce site utilise des cookies pour améliorer l'expérience utilisateur.
          </p>
        </div>
      </CookieConsent>
    </>
  );
}
