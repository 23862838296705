import React from "react";
import Icon from "../header/icon.jsx";

export default function Error() {
  return (
    <>
      <Icon />
      <div className="d-flex flex-column text-center">
        <h1>404</h1>
        <h2>Oups 🙈 Cette page n'existe pas ...</h2>
      </div>
    </>
  );
}
