import React from "react";
import "../../styles/prez/presentati.css";

const Presentati = ({ imageUrl, imageAlt, title }) => {
  return (
    <div className="BoxPropsPrez">
      <img src={imageUrl} alt={imageAlt} className="imagePrez" />
      <h4 style={{ whiteSpace: "pre-line" }}>{title}</h4>
    </div>
  );
};

export default Presentati;
