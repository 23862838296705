import React from "react";
import "../../styles/statistiq/statistiq3.css";

const Stats = ({ number, text, className }) => {
  return (
    <div className={`Stats d-flex flex-column text-center ${className}`}>
      <div className="spaCompAgen">
        <span className="StakeNumb">{number}</span>
        <p style={{ whiteSpace: "pre-line" }} className="texSta">
          {text}
        </p>
      </div>
    </div>
  );
};

export default function Statistiq3() {
  const data = [
    { number: "+150", text: "comptes gérés" },
    { number: "+10 000", text: "infographies postés" },
    { number: "+50 000 €", text: "de sponsorisations gérées" },
    { number: "+150", text: "influenceurs en collaboration" },
  ];
  const dataFormatOne = [
    { number: "+50 000 €", text: "de sponsorisations gérées" },
    { number: "+150", text: "influenceurs en collaboration" },
  ];
  const dataFormatTwo = [
    { number: "+150", text: "comptes gérés" },
    { number: "+10 000", text: "infographies postées" },
  ];

  return (
    <>
      <div className="space-ecart animate__animated animate__fadeIn">
        <div className="d-flex justify-content-around ">
          {data.map((item, index) => (
            <Stats key={index} number={item.number} text={item.text} />
          ))}
        </div>
      </div>
      <div className="space-ecart-two animate__animated animate__fadeIn">
        <div>
          <div className="alignNumAra ">
            {dataFormatOne.map((item, index) => (
              <Stats key={index} number={item.number} text={item.text} />
            ))}
          </div>
          <div className="alignNumAra">
            {dataFormatTwo.map((item, index) => (
              <Stats
                key={index}
                number={item.number}
                text={item.text}
                className={
                  item.number === "+150" && item.text === "comptes gérés"
                    ? "mobile-margin"
                    : ""
                }
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
