import React, { useState } from "react";
import { FaCheck, FaSpinner } from "react-icons/fa";
import "../../styles/Footer/footer.css";
import logo2 from "../../assets/logo2.png";
import { Link } from "react-router-dom";
import insta2 from "../../assets/logo_reseaux/insta2.png";
import facebook2 from "../../assets/logo_reseaux/facebook2.png";
import linkedind2 from "../../assets/logo_reseaux/linkedind2.png";
import axios from "axios";

async function addContactToBrevo(email) {
  try {
    await axios.post(
      "https://api.sendinblue.com/v3/contacts",
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key":
            "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",
        },
      }
    );

    console.log("Contact added successfully");
  } catch (e) {
    console.error("Error adding contact to Brevo: ", e);
  }
}

export default function Footer() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    }

    setEmailError("");
    setLoading(true);

    try {
      addContactToBrevo(email);
      setTimeout(() => {
        setSubmitted(false);
      }, 1500);

      setSubmitted(true);
    } catch (e) {
      console.error("Error adding document or sending email: ", e);
    } finally {
      setLoading(false);
    }
    setEmail("");
  };

  function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div>
        <footer className="foot">
          <div className="d-flex cnt">
            <div>
              <div className="triang_1 bg-light-subtle"></div>
            </div>
            <div>
              <div className="triang_2 bg-light-subtle"></div>
            </div>
          </div>
          <div className="grand_triang bg-light-subtle d-flex">
            <div className="firstStor">
              <div className="logo_2F">
                <Link to="/" onClick={handleLinkClick}>
                  <img src={logo2} alt="logo_full_marketing" className="LgF" />
                </Link>
              </div>

              <div className="iconiq ">
                <div className="in2 mis-D ">
                  <Link
                    to="https://www.instagram.com/fullmarketing.fr/"
                    className="navbar-brand"
                  >
                    <img
                      src={insta2}
                      alt="instagram_de_full_marketing"
                      className="rapti"
                    />
                  </Link>
                </div>
                <div className="fa2 mis-D rapti">
                  <Link
                    to="https://m.facebook.com/fullmarketing.fr"
                    className="navbar-brand"
                  >
                    <img
                      src={facebook2}
                      alt="facebook_de_full_marketing"
                      className="raptiFA"
                    />
                  </Link>
                </div>
                <div className="ke2 mis-D rapti">
                  <Link
                    to="https://www.linkedin.com/company/full-marketing-puteaux"
                    className="navbar-brand"
                  >
                    <img
                      src={linkedind2}
                      alt="linkedind_de_full_marketing"
                      className="rapti"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="twoStor">
              <div className=" text-dark besService">
                <p className="recup-E">Besoin de nos services ?</p>
                <Link to="/Contact" onClick={handleLinkClick}>
                  <button className="btn tact">Contactez-nous</button>
                </Link>
                <form className="inp-E" onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="Entrez votre mail"
                    className={`emev ${emailError ? "is-invalid" : ""}`}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                  />
                  {emailError && <p className="text-danger">{emailError}</p>}
                  <button
                    type="submit"
                    className={`bg-dark text-light  buE ${
                      loading ? "loading" : ""
                    }`}
                    disabled={loading || submitted}
                  >
                    {loading ? (
                      <FaSpinner className="spinner" />
                    ) : submitted ? (
                      <FaCheck className="check" />
                    ) : (
                      "Envoyer"
                    )}
                  </button>
                </form>
              </div>
              <div className="nav-linke d-flex">
                <nav className="cmAR">
                  <div className="spad">
                    <Link
                      to="/"
                      className="text-dark text-decoration-none"
                      onClick={handleLinkClick}
                    >
                      Accueil
                    </Link>
                  </div>
                  <div className="spad">
                    <Link
                      to="/"
                      className="text-dark text-decoration-none"
                      onClick={handleLinkClick}
                    >
                      Nos réalisations
                    </Link>
                  </div>
                  <div className="spad">
                    <Link
                      to="/Clients"
                      className="text-dark text-decoration-none"
                      onClick={handleLinkClick}
                    >
                      Nos clients
                    </Link>
                  </div>
                  <div className="spad">
                    <Link
                      to="/Contact"
                      className="text-dark text-decoration-none tex"
                      onClick={handleLinkClick}
                    >
                      Nous contacter
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div className="conforma bg-light-subtle ">
            <div className=" text-dark">
              <Link
                to="/Condgeneutil"
                className="text-dark"
                onClick={handleLinkClick}
              >
                • CGU
              </Link>
            </div>
            <div className=" text-dark">
              <Link
                to="/MentionLegal"
                className="text-dark"
                onClick={handleLinkClick}
              >
                • Mentions Légales
              </Link>
            </div>

            <div className=" text-dark">
              <Link
                to="/CookiePolicyPage"
                className="text-dark"
                onClick={handleLinkClick}
              >
                • Cookie
              </Link>
            </div>
            <div className=" text-dark">
              <Link
                to="/PolitiqueDeconf"
                className="text-dark"
                onClick={handleLinkClick}
              >
                • Politique de Confidentialité
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
