import React from "react";
import PheaClient from "../../components/p-hea/PheaClient";
import atelierDurum from "../../assets/logo_marq/atelierDurum.png";
import izzana from "../../assets/logo_marq/izzana.png";
import nomad from "../../assets/logo_marq/nomad.png";
import limou from "../../assets/logo_marq/logo-limou.png";
import ktAgancy from "../../assets/logo_marq/ktAgancy.png";
import aupti from "../../assets/logo_marq/aupti.png";
import "../../styles/client/client.css";
import { Helmet } from "react-helmet";

export default function Clients() {
  return (
    <>
      <div>
        <Helmet>
          <title>Full Marketing - Nos clients</title>
          <meta
            name="description"
            content="Nous vous partagons une liste de quelque colaborateur "
          />
        </Helmet>
        <section className="first-party">
          <PheaClient />
        </section>
        <section className="secnd">
          <div>
            <div className=" markDirc">
              <div>
                <img
                  src={atelierDurum}
                  alt="img_atelier_durum "
                  className="cliens"
                />
              </div>
              <div>
                <img src={izzana} alt="img_izzana" className="cliens" />
              </div>
              <div>
                <img src={nomad} alt="img_nomad " className="cliens" />
              </div>
            </div>
            <div className=" markDirc dus">
              <div>
                <img src={limou} alt="img_dbx " className="cliens limous " />
              </div>
              <div>
                <img
                  src={ktAgancy}
                  alt="img_ktAgancy"
                  className="ktag cliens"
                />
              </div>
              <div>
                <img src={aupti} alt="img_aupti" className="auptiP cliens" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
