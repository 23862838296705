import React, { useState } from "react";
import { FaCheck, FaSpinner } from "react-icons/fa";
import "../../styles/contact/Contact.css";
import "../../components/header/icon.jsx";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import axios from "axios";
import { Link } from "react-router-dom";
import Icon from "../../components/header/icon.jsx";
import { Helmet } from "react-helmet";

async function addContactToBrevo(name, firstname, email, numero) {
  try {
    await axios.post(
      "https://api.sendinblue.com/v3/contacts",
      {
        email: email,
        attributes: {
          NOM: name,
          PRENOM: firstname,
          NUMERO: numero,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key":
            "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",
        },
      }
    );

    console.log("Contact added successfully");
  } catch (e) {
    console.error("Error adding contact to Brevo: ", e);
  }
}

export default function Contact() {
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [numero, setNumero] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const handleConsentCheckboxChange = (event) => {
    setIsConsentChecked(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    }
    if (!isConsentChecked) {
      alert("Vous devez accepter le traitement de vos données pour continuer.");
      return;
    }

    setEmailError("");
    setLoading(true);

    try {
      const docRef = await addDoc(collection(db, "contact"), {
        name: name,
        firstname: firstname,
        email: email,
        numero: numero,
        message: message,
      });
      console.log("Document written with ID: ", docRef.id);

      addContactToBrevo(name, firstname, email);

      await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "vous contact depuis votre site web",
            email: "foodmarketingmanager@gmail.com",
          },
          to: [
            { email: "foodmarketingmanager@gmail.com" },
            { email: "volmarmanueldesign@gmail.com" },
            { email: "clement@foodmarketing.fr" },
          ],
          subject: "Vous contact depuis votre site web",
          htmlContent: `
          <p>Nom: ${name}</p>
          <p>Prénom: ${firstname}</p>
          <p>Email: ${email}</p>
          <p>numero: ${numero}</p>
          <p>Message: ${message}</p>
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key":
              "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",
          },
        }
      );

      setTimeout(() => {
        setSubmitted(false);
      }, 1500);

      setSubmitted(true);
      console.log("Email sent successfully");
    } catch (error) {
      console.error("Error adding contact to Brevo: ", error);
      console.log("Error response:", error.response);
      console.error("Error adding document or sending email: ", e);
    } finally {
      setLoading(false);
    }
    setName("");
    setFirstname("");
    setEmail("");
    setMessage("");
    setNumero("");
    setIsConsentChecked(false);
  };

  function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  return (
    <>
      <div>
        <Helmet>
          <title>Full Marketing - Contact</title>
          <meta
            name="description"
            content="prenez contact avec l'agence full marketing ,
            contacter une agence de marketing ,
            contacter une agence de marketing a puteaux , 
            contacter une agence 360°,  "
          />
        </Helmet>
        <div className="direct">
          <div className="ctacN ">
            <Icon />
            <div className="contac">
              <p className="cns">
                Contactez
                <br /> Nous
              </p>
            </div>
          </div>
          <div>
            <form
              className="formu bg-light-subtle text-black d-flex flex-column p-3 pos centered-form"
              onSubmit={handleSubmit}
            >
              <h2 className="p-3">Coordonnées</h2>
              <div className="d-flex flex-column m-2">
                <input
                  type="text"
                  placeholder="Nom"
                  className="rounded-pill spae crit "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="d-flex flex-column m-2">
                <input
                  type="text"
                  placeholder="Prénom"
                  className="rounded-pill spae crit"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </div>

              <div className="d-flex flex-column m-2">
                <input
                  type="email"
                  placeholder="Email"
                  className={`rounded-pill spae crit ${
                    emailError ? "is-invalid" : ""
                  }`}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && <p className="text-danger">{emailError}</p>}
              </div>

              <div className="d-flex flex-column m-2">
                <input
                  type="text"
                  placeholder="numero de telephone"
                  className="rounded-pill spae crit"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                />
              </div>

              <div className="d-flex m-2 flex-column">
                <textarea
                  placeholder="Message"
                  className="mess crit"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              <div className="d-flex flex-row chek_frm ">
                <input
                  type="checkbox"
                  id="dataConsent"
                  checked={isConsentChecked}
                  onChange={handleConsentCheckboxChange}
                />
                <label htmlFor="dataConsent" className="space_lab">
                  J'accepte que mes informations soient utilisées pour me
                  contacter et pour le traitement de ma demande.
                </label>
              </div>

              <button
                type="submit"
                className={`bg-black text-light ${loading ? "loading" : ""}`}
                disabled={loading || submitted}
              >
                {loading ? (
                  <FaSpinner className="spinner" />
                ) : submitted ? (
                  <FaCheck className="check" />
                ) : (
                  "Envoyer"
                )}
              </button>
              <div className="bg-black maps">
                <Link
                  to="https://www.google.com/search?rlz=1C1CHBF_frFR906FR906&sxsrf=APwXEdeO_WyHPwBPmiDAGucVvrbqpEMDmw:1686671079510&q=Full+Marketing&ludocid=5426108010681736659&gsas=1&lsig=AB86z5Woyoa6_dFWnOAciKD9F703&sa=X&ved=2ahUKEwie6q-0y8D_AhWAaqQEHWaYDsEQoAJ6BAgIEBE&biw=1536&bih=746&dpr=1.25"
                  className="text-decoration-none"
                >
                  Lien vers Google Maps
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
