import React from "react";
import { Link } from "react-router-dom";
import "../../styles/imageEx/imageEx1.css";
import Competance from "../Competance/Competance.jsx";
import budget from "../../assets/pix_marketing/iconmar/budget.png";
import cahier from "../../assets/pix_marketing/iconmar/cahier-des-charge.png";
import planning from "../../assets/pix_marketing/iconmar/planning.png";

export default function ImageEx1() {
  const data = [
    {
      imageUrl: cahier,
      imageAlt: "img_decrit",
      title: "Cahier des charges",
      text: ` Nous collectons les informations essentielles \npour pouvoir atteindre vos objectifs. `,
    },
    {
      imageUrl: budget,
      imageAlt: "Texte alternatif de l'image 2",
      title: "Budget",
      text: `Nous établissons un budget en fonction\n de vos ressources financières. `,
    },
    {
      imageUrl: planning,
      imageAlt: "Texte alternatif de l'image 2",
      title: "Planning éditorial",
      text: ` Nous créons un planning éditorial sur mesure,\n afin d'établir une stratégie de communication. `,
    },
  ];

  return (
    <>
      <div className="psatch ">
        <div className="margeMoin">
          <div className="contDetailService">
            <div className="expliciDetailText">
              <span className="utd">VOTRE ABONNEMENT</span>
              <h2 className="titrHDeux">
                Personnalisez votre forfait en 3 étapes
              </h2>
              <p>
                Profitez de nos conseils et créez avec nous un forfait qui
                répond totalement
                <br />à vos besoins, vos objectifs et votre budget.
              </p>
            </div>
            <div className="aragnComCol">
              {data.map((item, index) => (
                <Competance
                  key={index}
                  imageUrl={item.imageUrl}
                  imageAlt={item.imageAlt}
                  title={item.title}
                  text={item.text}
                />
              ))}
            </div>
            <div className="CompetancButt">
              <Link to="/Contact">
                <button className="btn border-light text-dark bg-light border-3 buttCompetanceAreng text-center">
                  Nous contacter
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
