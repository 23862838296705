import React from "react";
import "../../styles/phea/phea3.css";
import { Link } from "react-router-dom";
import Icon2 from "../header/icon2.jsx";
import ImageCarousel from "../ImageCarousel/ImageCarousel.jsx";
/* import imagA from "../../assets/pix_marketing/imagA.png"; */
import aupav from "../../assets/pix_marketing/marjimg/aupavillon.png";
import limousine from "../../assets/pix_marketing/marjimg/limousine.png";
import steakhouse from "../../assets/pix_marketing/marjimg/steakhouse.png";
import lamanu from "../../assets/pix_marketing/marjimg/lamanu.png";
import le47 from "../../assets/pix_marketing/marjimg/le47.png";
import flec from "../../assets/pix_marketing/flech.png";

export default function Phea3() {
  const images = [limousine, steakhouse, aupav, lamanu, le47];

  return (
    <>
      <div className=" pheaAgence">
        <Icon2 />
        <div className="partyHeaderMarketing">
          <div className="titleTextMarketing ">
            <div className="d-flex flex-column">
              <h1>
                Exploitez votre potentiel
                <br /> sur les réseaux sociaux
              </h1>
              <p className="pPartyHea">
                Touchez efficacement votre clientèle cible avec une stratégie
                <br />
                sur-mesure, personnalisée, préparée par notre équipe d'experts.
              </p>
              <div className="buttonArangem">
                <Link to="/Clients">
                  <button className="btn border-light text-light border-3 btnaAjusNumbOne  ">
                    Nos Clients
                  </button>
                </Link>
                <Link to="/Contact">
                  <button className="btn border-light text-dark bg-light border-3 btnaAjusNumbTwo ">
                    Nous contacter
                  </button>
                </Link>
              </div>

              <div className="contFlehAgenc">
                <img src={flec} alt="image_fleche" className="flehAgenc" />
              </div>
            </div>
          </div>
          <div>
            <ImageCarousel images={images} />
          </div>
        </div>
      </div>
    </>
  );
}
