import React from "react";
import "../../styles/imageEx/imageEx3.css";
import infographe from "../../assets/pix_marketing/marjimg/infographie.png";
import maquette from "../../assets/pix_marketing/marjimg/maquette.png";
import Shopho from "../../assets/pix_marketing/marjimg/Shopho.png";
import photo from "../../assets/pix_marketing/marjimg/photo.png";
import ecranSite from "../../assets/pix_marketing/marjimg/ecranSite.png";
import { Link } from "react-router-dom";

export default function ImageEx3() {
  return (
    <>
      <div className="RealisAgence">
        <h2 className="text-center hDeuxRealis">Nos réalisations</h2>
        <div className="AgenceImgRealisa">
          <div className="OnePartReal">
            <div>
              <img
                src={photo}
                alt="Shooting_photorestaurent"
                className="biReaSpac taillBigImgReali"
              />
            </div>
            <div>
              <img
                src={Shopho}
                alt="Shooting_photorestaurent"
                className="taillSmalImgReali"
              />
            </div>
          </div>
          <div className="twoPartReal ">
            <div>
              <img
                src={infographe}
                alt="Description_img"
                className="reaMarAgenc taillSmalImgReali"
              />
            </div>
            <div>
              <img
                src={ecranSite}
                alt="ecran_de_site_web"
                className="reaMarAgenc taillSmalImgReali"
              />
            </div>
            <div>
              <img
                src={maquette}
                alt="Description_img"
                className="reaMarAgenc taillSmalImgReali"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="AgenceImgRealisaMobile">
            <img
              src={photo}
              alt="Shooting_photorestaurent"
              className="biReaSpacMobile taillBigImgRealiMobile"
            />
            <div className="reaAgenImgPartOne">
              <img
                src={Shopho}
                alt="Shooting_photorestaurent"
                className="taillSmalImgRealiMobile reaMarAgencMobile"
              />
              <img
                src={infographe}
                alt="Description_img"
                className="reaMarAgencMobile taillSmalImgRealiMobile"
              />
            </div>
            <div className="reaAgenImgPartTw">
              <img
                src={ecranSite}
                alt="Shooting_photorestaurent"
                className="taillSmalImgRealiMobile reaMarAgencMobile"
              />
              <img
                src={maquette}
                alt="Description_img"
                className="reaMarAgencMobile taillSmalImgRealiMobile"
              />
            </div>
          </div>
        </div>
        <div className="ButtoRealiBox">
          <Link to="/Contact">
            <button className="btn border-light text-light  border-3 text-center imageButtReal">
              Contactez-nous
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
