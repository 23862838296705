import React from "react";
import Phea3 from "../../components/p-hea/Phea3";
import Statistiq3 from "../../components/statis/statistiq3";
import "../../styles/Marketing/marketing.css";
import AlignlogoOne from "../../components/align_logo/AlignlogoOne";
import ImageEx1 from "../../components/image_ex/ImageEx1";
import ImageEx3 from "../../components/image_ex/ImageEx3";
import Prez from "../../components/Prez/Prez";
/* import MinSlide from "../../components/slide/mini_slide/MinSlide";
import MinSlide2 from "../../components/slide/mini_slide/MinSlide2"; */
import { Helmet } from "react-helmet";

export default function Marketing() {
  return (
    <>
      <div>
        <Helmet>
          <title>Full Marketing - Marketing digital</title>
          <meta
            name="description"
            content="full marketing vous propose un service de marketing digital,marketing digital puteaux, agence de marketing digital puteaux, shooting photo a la defence "
          />
        </Helmet>
        <section className="first-party">
          <Phea3 />
        </section>
        <section className="secondo-party">
          <article>
            <AlignlogoOne />
          </article>
        </section>
        <section className="tres">
          <ImageEx1 />
          <Prez />
        </section>
        <section className="quatro">
          <Statistiq3 />
        </section>
        <section className="cinqo">
          <ImageEx3 />
        </section>
        {/* <section className="cinq">
          <p className="h_tr e_min">NOS SECTEURS D’ACTIVITÉS</p>
          <div className=" mavc">
            <div className="carPartMar1">
              <MinSlide />
            </div>
            <div className="carPartMar2">
              <MinSlide2 />
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
}
