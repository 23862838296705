import React, { useState, useRef } from "react";
import "../../styles/Carrousel/Carrousel.css";

const ImageCarousel = ({
  images,
  autoPlay = true,
  autoPlayInterval = 3000,
}) => {
  const [startPosition, setStartPosition] = useState(null);
  const [startScrollLeft, setStartScrollLeft] = useState(null);
  const carouselRef = useRef(null);

  const handleMouseDown = (event) => {
    event.preventDefault();
    setStartPosition(event.clientX);
    setStartScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    const endPosition = event.clientX;
    const difference = startPosition - endPosition;

    if (Math.abs(difference) > 100) {
      carouselRef.current.scrollLeft = startScrollLeft + difference;
    }
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setStartPosition(e.touches[0].clientX);
    setStartScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    const endPosition = e.changedTouches[0].clientX;
    const difference = startPosition - endPosition;

    if (Math.abs(difference) > 100) {
      carouselRef.current.scrollLeft = startScrollLeft + difference;
    }
  };

  return (
    <div
      className="carousel-container"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      ref={carouselRef}
    >
      <div className="carousel-images">
        {images.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Slide ${index}`}
            className="carousel-image"
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
